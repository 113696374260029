import React from "react";
import { Popover, message } from "antd";
import { observer } from "mobx-react";
import * as _ from "lodash";
import "./index.less";
const SearchImageInput = Loader.loadBusinessComponent("SearchImageInput");
const ObjectMapSex = Loader.loadBusinessComponent("Statistics", "ObjectMapSex");
const ObjectMapAge = Loader.loadBusinessComponent("Statistics", "ObjectMapAge");
const ObjectMapLabel = Loader.loadBusinessComponent(
  "Statistics",
  "ObjectMapLabel"
);
const ObjectMapPlace = Loader.loadBusinessComponent(
  "Statistics",
  "ObjectMapPlace"
);
const IconFont = Loader.loadBaseComponent("IconFont");

@Decorator.withEntryLog()
@Decorator.businessProvider("tab", "user")
@observer
class ObjectMapPersonnel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      sexStatistics: undefined,
      ageStatistics: undefined,
      timeStatistics: undefined,
      placeTypeStatistics: undefined,
      countLoading: true
    };
    this.sexRef = React.createRef();
    this.ageRef = React.createRef();
    this.labelRef = React.createRef();
    this.placeRef = React.createRef();
    this.getPersonCount();
    this.countPerson();
  }

  // 获取人员档案总数
  getPersonCount = () => {
    Service.person.getPersonCount().then(res => {
        this.setState({
          count: res.data,
          countLoading: false
        });
    });
  };

  // 获取场所统计
  countPerson = () => {
    const { user } = this.props;
    Service.organization
      .getPlaceIdsByUserId({
        organizationId: user.userInfo.organizationId
      })
      .then(res => {
        let option = {
          placeIds: res.data.placeIds || [],
          tagTypes: [100000, 100800, 119100, 118700]
        };
        if (!!option.placeIds.length) {
          Service.place.countPerson(option).then(res => {
            let data = res.data,
              sexStatistics = undefined,
              ageStatistics = undefined,
              timeStatistics = undefined,
              placeTypeStatistics = undefined;
            data.map(v => {
              if (v) {
                if (v.tagType === "100000") {
                  // 性别统计
                  sexStatistics = v.tagCounts;
                }
                if (v.tagType === "100800") {
                  // 年龄段
                  ageStatistics = v.tagCounts;
                }
                if (v.tagType === "119100") {
                  // 场所类型人员统计
                  placeTypeStatistics = v.tagCounts;
                }
                if (v.tagType === "118700") {
                  // 时空标签
                  timeStatistics = v.tagCounts;
                }
              }
            });
            this.setState({
              sexStatistics,
              ageStatistics,
              timeStatistics,
              placeTypeStatistics
            });
          });
        } else {
          this.setState({
            sexStatistics: undefined,
            ageStatistics: undefined,
            timeStatistics: undefined,
            placeTypeStatistics: undefined
          });
        }
      });
  };
  search = item => {
    const { tab, location } = this.props;
    if (
      !item.pictureUrl &&
      item.keywords.length === 0 &&
      item.recommends.length === 0
    ) {
      message.info("请输入关键字进行搜索");
      return;
    }
    const id = Utils.uuid();
    window.LM_DB.add("parameter", {
      id,
      searchData: item
    }).then(() => {
      tab.goPage({
        moduleName: "objectMapPersonnelList",
        location,
        isUpdate: true,
        data: { id }
      });
    });
  };

  render() {
    let {
      count,
      sexStatistics,
      ageStatistics,
      timeStatistics,
      placeTypeStatistics,
      countLoading
    } = this.state;
    let nums = Utils.thousand(count).split("");
    // console.log(count.toLocaleString(),nums)
    return (
      <div className="object-map-personnel">
        <header className="personnel-header">
          <p className="header-title">人员档案</p>
          {!countLoading ? (
            <>
              <p className="sub-header-title">总人数</p>
              <div className="header-title-nums">
               { nums.map((v, k) => isNaN(v*1) ? '， ' :
                <span className={`header-title-num animate-num`} key={k}>
                  {v}
                </span>
                )}
              </div>
            </>
          ) : (
            <div className='header-title-loading'/>
          )}
        </header>
        <SearchImageInput search={this.search} />
        <div className="perosnnel-footer">
          {/* {sexStatistics && ( */}
          <div className="footer-box" ref={this.sexRef}>
            <Popover
              placement="top"
              content={<ObjectMapSex data={sexStatistics} />}
              getPopupContainer={() => this.sexRef.current}
              className="pop-sex"
              overlayClassName="pop-sex-content"
            >
              <div className="object-box-content">
                <div className="btn">
                  <IconFont type={"icon-S_AID_Sex"} theme="outlined" />
                </div>
                <div className="info">性别分布</div>
              </div>
            </Popover>
          </div>
          {/* )} */}
          {/* {ageStatistics && ( */}
          <div className="footer-box" ref={this.ageRef}>
            <Popover
              placement="top"
              content={<ObjectMapAge data={ageStatistics} />}
              getPopupContainer={() => this.ageRef.current}
              overlayClassName="pop-age-content"
            >
              <div className="object-box-content">
                <div className="btn">
                  <IconFont type={"icon-S_AID_Age"} theme="outlined" />
                </div>
                <div className="info">年龄分布</div>
              </div>
            </Popover>
          </div>
          {/* )} */}
          {/* {timeStatistics && ( */}
          <div className="footer-box" ref={this.labelRef}>
            <Popover
              placement="top"
              content={<ObjectMapLabel data={timeStatistics} total={count} />}
              getPopupContainer={() => this.labelRef.current}
              className="pop"
              overlayClassName="pop-label-content"
            >
              <div className="object-box-content">
                <div className="btn">
                  <IconFont
                    type={"icon-M_Files_ThingsAnalysis"}
                    theme="outlined"
                  />
                </div>
                <div className="info">时间活动规律</div>
              </div>
            </Popover>
          </div>
          {/* )} */}
          {/* {placeTypeStatistics && ( */}
          <div className="footer-box" ref={this.placeRef}>
            <Popover
              placement="top"
              content={<ObjectMapPlace data={placeTypeStatistics} />}
              getPopupContainer={() => this.placeRef.current}
              overlayClassName="pop-place-content"
            >
              <div className="object-box-content">
                <div className="btn">
                  <IconFont type={"icon-L_Place_Place"} theme="outlined" />
                </div>
                <div className="info">各类场所人员出入分布</div>
              </div>
            </Popover>
          </div>
          {/* )} */}
        </div>
      </div>
    );
  }
}

export default ObjectMapPersonnel;
